<template>
  <router-view />
  <SLoading
    v-model="loading.visible.value"
    :loading-text="loading.text.value"
    :loading-color="loading.color.value"
    teleport-disabled
  />
</template>

<script setup lang="ts">
/* 內部方法 */
import { isDevelopment } from '@sms/common/utils/helper';
import useLoading from '@sms/common/composables/useLoading';

/* 內部組件 */
import SLoading from '@sms/components/SLoading/SLoading.vue';

/* 型別 */
import CustomError from '@sms/common/models/CustomError';

/* 全域 Loading 控制 */
const loading = useLoading();

// 點一次就全螢幕
document.documentElement.addEventListener('click', async () => {
  if (!document.fullscreenElement) {
    document.documentElement.requestFullscreen({ navigationUI: 'hide' });
    // 設備螢幕不鎖屏
    if ('wakeLock' in navigator) {
      try {
        await navigator.wakeLock.request('screen');
      } catch (error) {
        if (error instanceof CustomError) {
          if (isDevelopment) console.error(error.message);
        }
      }
    }
  }
});
</script>

<style lang="scss">
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

html,
body,
#app {
  height: 100%;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  user-select: none;
}
</style>
