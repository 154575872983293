/* 外部方法 */
import { createRouter, createWebHashHistory } from 'vue-router';

/* 型別 */
import type { RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
  {
    path: '',
    name: 'login',
    component: () => import('@/views/TabletLogin.vue')
  },
  {
    path: '/:deskId',
    name: 'deskLogin',
    component: () => import('@/views/DesktopLogin.vue')
  },
  {
    path: '/tableinfo',
    name: 'tableinfo',
    component: () => import('@/views/TableInfo.vue')
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes
});

export default router;
