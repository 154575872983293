/* 外部方法 */
import { createApp } from 'vue';

/* 內部方法 */
import router from './router';
import { pinia } from './store';

/* 內部組件 */
import App from './App.vue';
import SLoading from '@sms/components/plugins/SLoading';

createApp(App).use(pinia).use(router).use(SLoading).mount('#app');
