import { defineStore } from 'pinia';
import DeskSet from '@sms/common/models/DeskSet';

// store
export default defineStore('root', {
  state: () => ({
    deskInfo: new DeskSet(),
    template: ''
  }),
  actions: {
    setDeskInfo(desk: DeskSet) {
      this.deskInfo = desk;
    },
    setTemplate(template: string) {
      this.template = template;
    }
  }
});
